/* Built In Imports */
import NextLink from 'next/link';
import { useState } from 'react';

/* External Imports */
import { Box, Link, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import LikeBookmark from '@components/Utility/LikeBookmark';
import { refineUrlDomain } from '@components/Utility/utils';
import config from '@config';
import { LangStyle } from 'constants/index';

/* Services */

/**
 * Renders the Media Article Card Component
 *
 * @param {string} title - title of component
 * @param {string} lang - Language for page
 * @param {string} summary - Summary / Description of component
 * @param {boolean} isShareEnabled - Social links of cards
 * @param {boolean} isLiked - Card is liked or not by the user
 * @param {boolean} isBookmarked - Card is bookmarked or not
 * @returns {ReactElement} MediaArticleCard Component
 */
const MediaArticleCard = ({
  title,
  summary,
  contentType,
  uuid,
  createdAt,
  updatedAt,
  url,
  cardImage,
  status,
  isShareEnabled = true,
  isLiked,
  isBookmarked,
  lang,
  region,
  updateCards,
  key,
  articleposition,
}) => {
  const [isShareSlideShow, setIsShareSlideShow] = useState(false);
  let type = contentType?.split('-')?.join(' ');
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  let date = new Date(createdAt).toLocaleDateString('en-US', options);
  const shareHandler = () => {
    setIsShareSlideShow(!isShareSlideShow);
  };
  const rc_position = articleposition + 1;

  const articleClick = () => {
    if (typeof window !== 'undefined') {
      window.location.href = url ? url : '';
      window.dataLayer.push({
        event: 'related_content',
        rc_title: title,
        rc_position: rc_position,
      });
    }
  };

  return (
    <Box
      maxW={{ base: '302px', sm: '330px', md: '330px', lg: '370px' }}
      key={uuid}
      mx="auto"
      sx={{ direction: lang == 'ar' ? 'rtl' : 'ltr' }}
      onClick={articleClick}
    >
      <Box
        mb="30px"
        minHeight={{ base: '470px', lg: '450px' }}
        backgroundColor="#fff"
        height="auto"
        margin={'0 0 30px 0'}
        // maxWidth="400px"
        boxShadow={'0 0 16px 0 rgb(0 0 0 / 40%)'}
        position="relative"
        _hover={{
          boxShadow: '0 0 48px 0 rgb(0 0 0 / 25%)',
        }}
      >
        <NextLink
          href={refineUrlDomain(url ? url : '', region, lang)}
          legacyBehavior
        >
          <Link>
            <Box
              overflow="hidden"
              display="inline-block"
              maxHeight={{ base: '198px', lg: '207px' }}
              color="#cf4824"
              textDecoration="none"
              className="card-image"
              cursor="pointer"
            >
              <Box
                minHeight={{ base: '206px', md: '200px', lg: '224px' }}
                position="relative"
                // className="card-image"
              >
                <LazyLoadImageComponent
                  display="block"
                  clipPath="polygon(0 0, 100% 0, 100% 90%, 0% 100%)"
                  w="100%"
                  // height={{ base: '206px', md: '200px', lg: '224px' }}
                  verticalAlign="middle"
                  src={cardImage?.url}
                  alt={cardImage?.alt}
                  title={cardImage?.title}
                  position="relative"
                  minHeight={{ base: '206px', md: '200px', lg: '224px' }}
                  // bgColor="#EEEEEE"
                />
              </Box>
            </Box>
          </Link>
        </NextLink>
        <Box
          fontFamily={
            LangStyle[lang]?.fontFamily
              ? LangStyle[lang].fontFamily
              : 'FedraSerifAStdBook'
          }
          fontSize="16px"
          textAlign="right"
          color="#676055"
          mr="15px"
          textTransform="capitalize"
        >
          {type} &nbsp;
          <Box float="left" ml="25px"></Box>
          {contentType === 'sadhguru-spot' && (
            <LazyLoadImageComponent
              src={`${config.staticPath}/assets/images/article.svg`}
              verticalAlign="middle"
              className="card-image"
              alt=""
              title=""
              width="20px"
            />
          )}
          {contentType !== 'sadhguru-spot' && (
            <LazyLoadImageComponent
              src={`${config.staticPath}/assets/images/${contentType}.svg`}
              verticalAlign="middle"
              className="card-image"
              alt=""
              title=""
              width="20px"
            />
          )}
        </Box>
        <Box
          display="-webkit-box"
          p={{ base: '0 3vw', md: '0 3vw', lg: '0 2vw' }}
          overflow="hidden"
          maxHeight="67.5px !important"
          fontFamily="FedraSerifAStdBook"
          fontSize="20px"
          textAlign="left"
          color="#28231e"
          wordwrap="break-word"
          mt={{ base: '12px', md: '15px', lg: '20px' }}
          className="isha-card-podcast-title"
        >
          <NextLink
            href={refineUrlDomain(url ? url : '', region, lang)}
            passHref
            prefetch={false}
            legacyBehavior
          >
            <Link
              _hover={{
                textDecoration: 'none',
              }}
            >
              <Box
                title={title}
                lineHeight="1.3em !important"
                maxHeight="69.5px !important"
                _hover={{
                  textDecoration: 'none',
                }}
                _focus={{ boxShadow: 'none' }}
                fontFamily={
                  LangStyle[lang]?.fontFamily
                    ? LangStyle[lang].fontFamily
                    : 'FedraSerifAStdBook'
                }
                fontSize={{
                  base: LangStyle[lang]?.fontSize
                    ? LangStyle[lang].fontSize
                    : '20px',
                  lg: LangStyle[lang]?.fontSize
                    ? LangStyle[lang].fontSize
                    : '24px',
                }}
                cursor="pointer"
              >
                {title}
              </Box>
            </Link>
          </NextLink>
        </Box>
        <Box
          display="-webkit-box"
          p={{ base: '1vw 3vw', lg: '1vw 2vw',xl:"0.7vw 2vw" }}
          mb={{ base: '30px' }}
          height={{ base: '105px' }}
          textOverflow="ellipsis"
          overflow="hidden"
          lineHeight="20px"
          maxHeight={{ base: '105px', md: '90px', lg: '74px' }}
          fontFamily="FedraSansStd-book"
          fontSize="14px"
          textAlign="left"
          color="#676055"
          wordwrap="break-word"
          className="isha-rp-desc"
        >
          <NextLink
            href={refineUrlDomain(url ? url : '', region, lang)}
            passHref
            prefetch={false}
            legacyBehavior
          >
            <Link
              _hover={{
                textDecoration: 'none',
              }}
            >
              <Box
                textDecoration="none"
                _hover={{
                  textDecoration: 'none',
                }}
                _focus={{ boxShadow: 'none' }}
                display="-webkit-box"
                fontFamily={
                  LangStyle[lang]?.fontFamily
                    ? LangStyle[lang].fontFamily
                    : 'FedraSansStd-book'
                }
                cursor="pointer"
              >
                {summary}
              </Box>
            </Link>
          </NextLink>
        </Box>
        <Box
          p={{ base: '2vw 5vw', lg: '10px 10px 5px 29px' }}
          width="100%"
          fontSize="13px"
          color="#8a8173"
          fontFamily="FedraSansStd-medium"
          bottom="5px"
          position="absolute"
        >
          <Box float="left">
            {/* Sep 22, 2021 */}
            {date}
          </Box>
          {isShareEnabled && (
            <Box
              float="right"
              width={{ base: '145px', lg: '180px' }}
              display="flex"
            >
              <LikeBookmark
                id={uuid}
                isLiked={isLiked}
                isBookmarked={isBookmarked}
                lang={lang}
                updateCards={updateCards}
              />
              <Box float="left" p="0 0.5vw">
                <LazyLoadImageComponent
                  height="24px"
                  minHeight="24px"
                  width="24px"
                  // p="0 0.5vw"
                  float="right"
                  cursor="pointer"
                  pos="relative"
                  zIndex="1"
                  src={`${config.imgPath}/d/46272/1663579985-share.svg`}
                  onClick={shareHandler}
                />
                <Box
                  overflow="hidden"
                  background="#f9f6f1"
                  position="absolute"
                  width="85%"
                  right="40px"
                  bottom="11px"
                  padding="4px 0 6px"
                  borderRadius="5px"
                  display={isShareSlideShow ? 'block' : 'none'}
                >
                  <Box height="30px">
                    <Text
                      as="span"
                      display="flex"
                      data-a2a-url={`${config.BASE_PATH}${url}`}
                      data-a2a-title="How to Cope with Job Loss during the Pandemic? | Sadhguru Answers"
                      // style="line-height: 32px;"
                    >
                      <Link
                        target="_blank"
                        href={`https://www.addtoany.com/add_to/whatsapp?linkurl=${config.BASE_PATH}${url}`}
                        rel="nofollow noopener"
                      >
                        <Text
                          as="span"
                          display="block"
                          m="5px 6px"
                          cursor="pointer"
                          height="27px"
                          width="25px"
                          background={`url('${config.imgPath}/d/46272/1663580397-whatsapp.svg')`}
                          backgroundRepeat="no-repeat"
                          _hover={{
                            background: `url('${config.imgPath}/d/46272/1663580395-whatsapp1.svg')`,
                          }}
                        ></Text>
                      </Link>
                      <Link
                        target="_blank"
                        href={`https://www.addtoany.com/add_to/facebook?linkurl=${config.BASE_PATH}${url}`}
                        rel="nofollow noopener"
                      >
                        <Text
                          as="span"
                          display="block"
                          m="5px 6px"
                          cursor="pointer"
                          height="27px"
                          width="25px"
                          background={`url('${config.imgPath}/d/46272/1663580393-facebook.svg')`}
                          backgroundRepeat="no-repeat"
                          _hover={{
                            background: `url('${config.imgPath}/d/46272/1663580389-facebook1.svg')`,
                          }}
                        ></Text>
                      </Link>
                      <Link
                        target="_blank"
                        href={`https://www.addtoany.com/add_to/twitter?linkurl=${config.BASE_PATH}${url}`}
                        rel="nofollow noopener"
                      >
                        <Text
                          as="span"
                          display="block"
                          m="5px 6px"
                          cursor="pointer"
                          height="27px"
                          width="25px"
                          background={`url('${config.imgPath}/d/46272/1663580385-twitter.svg')`}
                          backgroundRepeat="no-repeat"
                          _hover={{
                            background: `url('${config.imgPath}/d/46272/1663580381-twitter1.svg')`,
                          }}
                        ></Text>
                      </Link>
                      <Link
                        target="_blank"
                        href={`https://www.addtoany.com/add_to/linkedin?linkurl=${config.BASE_PATH}${url}`}
                        rel="nofollow noopener"
                      >
                        <Text
                          as="span"
                          display="block"
                          m="5px 6px"
                          cursor="pointer"
                          height="27px"
                          width="25px"
                          background={`url('${config.imgPath}/d/46272/1663580377-linkedin.svg')`}
                          backgroundRepeat="no-repeat"
                          _hover={{
                            background: `url('${config.imgPath}/d/46272/1663580369-linkedin1.svg')`,
                          }}
                        ></Text>
                      </Link>
                      <Link
                        target="_blank"
                        href={`https://www.addtoany.com/add_to/email?linkurl=${config.BASE_PATH}${url}`}
                        rel="nofollow noopener"
                      >
                        <Text
                          as="span"
                          display="block"
                          m="5px 6px"
                          cursor="pointer"
                          height="27px"
                          width="25px"
                          background={`url('${config.imgPath}/d/46272/1663580357-message.svg')`}
                          backgroundRepeat="no-repeat"
                          _hover={{
                            background: `url('${config.imgPath}/d/46272/1663580355-message1.svg')`,
                          }}
                        ></Text>
                      </Link>
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      <style jsx global>{`
        .card-image {
          clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
        }
        .isha-card-podcast-title {
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .isha-rp-desc {
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      `}</style>
    </Box>
  );
};

export default MediaArticleCard;
